import validator from "validator";
import jCaptcha from "../../../node_modules/js-captcha/dist/js/index";

const contacto = () => {
	let formaData = null;

	function getForma() {
		const elements = document.getElementById("formaContacto").elements;
		let obj = {};
		for (let i = 0; i < elements.length; i++) {
			let item = elements.item(i);
			obj[item.name] = item.value;
		}
		formaData = obj;
	}

	// Init captcha
	let captcha = false;

	let myCaptcha = new jCaptcha({
		el: ".jCaptcha",
		canvasClass: "jCaptchaCanvas",
		canvasStyle: {
			// required properties for captcha stylings:
			width: 100,
			height: 15,
			textBaseline: "top",
			font: "15px Arial",
			textAlign: "left",
			fillStyle: "#001d85",
		},
		// set callback function for success and error messages:
		callback: (response, $captchaInputElement, numberOfTries) => {
			if (response == "success") {
				captcha = true;
			}
			if (response == "error") {
				captcha = false;
				alert("Por favor completa la prueba captcha correctamente.");
				document.querySelector(".jCaptcha").classList.add("is-invalid");
				return false;
			}
		},
	});

	function validarCamposForma(nombre, email, tel) {
		// nombre
		if (nombre === "" || nombre === null) {
			document.querySelector("#nombre").classList.add("is-invalid");
			return false;
		} else {
			document.querySelector("#nombre").classList.remove("is-invalid");
		}

		// nombre
		if (tel === "" || tel === null) {
			document.querySelector("#tel").classList.add("is-invalid");
			return false;
		} else {
			document.querySelector("#tel").classList.remove("is-invalid");
		}

		// email valido
		if (!validator.isEmail(email)) {
			document.querySelector("#email").classList.add("is-invalid");
			return false;
		} else {
			document.querySelector("#email").classList.remove("is-invalid");
		}

		// Captcha
		myCaptcha.validate();

		if (captcha === true) {
			// desahabilita el boton de enviar
			document.querySelector("#submit").setAttribute("disabled", true);

			// Obtener los datos de la forma para el envio
			const formaDatosParaEnviar = new FormData(
				document.getElementById("formaContacto")
			);

			// const url = "https://shotpruebas.com/hielo-star/php/process.php";
			const url = "https://hielitosstar.com/php/process.php";
			const data = formaDatosParaEnviar;
			const params = {
				body: data,
				method: "POST",
			};

			fetch(url, params)
				.then((data) => {
					return data;
				})
				.then((response) => {
					// console.log(response.status);
					if (response.status == 200) {
						location.href = "gracias.html";
					} else {
						console.log(response);
					}
				})
				.catch((error) => console.log(error));
		}
	}

	document.addEventListener("DOMContentLoaded", function () {
		const formulario = document.querySelector("#formaContacto");

		formulario.addEventListener("submit", (e) => {
			e.preventDefault();
			getForma();
			validarCamposForma(
				formaData.nombre,
				formaData.email,
				formaData.tel
			);
		});
	});
};

export default contacto;
